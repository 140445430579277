import { render, staticRenderFns } from "./NewSalesV2OneWoman.vue?vue&type=template&id=d32c4038&scoped=true"
import script from "./NewSalesV2OneWoman.vue?vue&type=script&lang=js"
export * from "./NewSalesV2OneWoman.vue?vue&type=script&lang=js"
import style1 from "./NewSalesV2OneWoman.vue?vue&type=style&index=1&id=d32c4038&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d32c4038",
  null
  
)

export default component.exports